<template>
	<div class="container">
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="yePay(1)">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<div class="good-item relative">
			<div class="good-item-top">
				<img :src="info.detailsPic" class="good-item-img" />
			</div>
			<div class="food-item-finish-icon" v-if='info.status ==2'>已结束</div>
			<div class="good-item-num" v-else>NO.1</div>
		</div>
		<div class="good-item-bottom">
			<div class="flex-row align-center">
				<!-- <img src="../assets/market/hot_icon.png" class="new-icon" /> -->
				<div class="good-item-name">{{info.name}}</div>
			</div>
			<div class="good-item-desc">{{info.summary}}</div>
			<div class="flex-row align-center mt20">
				<div class="good-item-price">￥{{info.price}}</div>
				<div class="good-item-qi">起</div>
			</div>
			<div class="data-sec">
				<div class="flex-row align-center">
					<div class="progress-bg">
						<div class="progress" :style="'width:'+ (info.progress>100?100:info.progress) + '%'"></div>
					</div>
					<img src="../assets/market/huomiao.png" class="huomiao-icon" />
					<div class="progress-num">{{info.progress}}%</div>
				</div>
				<div class="flex-row align-center space-between num-list">
					<div class="flex-column align-center num-item">
						<div class="num">{{info.quantity-info.surplusQuantity}}次</div>
						<div class="num-name">成交数</div>
					</div>
					<div class="flex-column align-center num-item">
						<div class="num">{{info.soldOutAmount}}万</div>
						<div class="num-name">已筹金额</div>
					</div>
					<div class="flex-column align-center num-item">
						<div class="num">{{info.targetAmount}}万</div>
						<div class="num-name">目标金额</div>
					</div>
					<div class="flex-column align-center num-item">
						<div class="num">{{info.surplusDay}}天</div>
						<div class="num-name">剩余时间</div>
					</div>
				</div>
			</div>
			<div class="sponsor-sec">
				<div class="flex-row align-center">
					<img src="../assets/market/sponsor_icon.png" class="sponsor-icon" />
					<div class="sponsor-name">众筹发起方：{{info.issuerName}}</div>
				</div>
				<div class="relative sponsor-con-sec">
					<img src="../assets/market/sponsor_bg.png" class="sponsor-con-bg" />
					<div class="sponsor-con">众筹资金由众筹发起方获取，资金的使用由发起方及支持者社区决定，发起方及支持者社区为全部法律责任的承担主体。“Hello
						Web3!“仅为网络空间及技术等服务的提供方，并不是发起方或支持者中的任何一方。</div>
				</div>
			</div>
			<div class="sponsor-sec">
				<div class="flex-row align-center">
					<img src="../assets/market/risk_icon.png" class="sponsor-icon" />
					<div class="sponsor-name">风险提示</div>
				</div>
				<div class="relative sponsor-con-sec">
					<img src="../assets/market/sponsor_bg.png" class="sponsor-con-bg" />
					<div class="sponsor-con">
						众筹并非传统商品交易，存在一定风险。“项目详情”由众筹发起方独立提供，参与用户有义务进行核实，如发现不实，欢迎向平台方投诉举报。众筹不是投资，请根据自己的判断选择、支持众筹项目。</div>
				</div>
			</div>
			<div class="detail-sec">
				<div class="flex-row align-center">
					<div class="detai-title_left"></div>
					<div class="detai-title">项目详情（由众筹发起方提供）</div>
					<div class="detai-title_right"></div>
				</div>
				<div class="detail-con" v-html="info.details"></div>
			</div>
		</div>

		<div class="bot-sec flex-row align-center">
			<div class="flex-row align-center justify-center report-btn">
				<img src="../assets/market/report_icon.png" class="report-icon" />
				<div class="report-name">投诉举报</div>
			</div>
			<div class="flex-row align-center justify-center support-btn" @click="checkBuyInfo(1)">
				<div class="support-name">立即支持</div>
				<div class="support-num">{{info.price}}元</div>
			</div>
		</div>
		<div class="model" v-if="isShowSupportModal"></div>
		<div class="flex-column support-modal" v-if="isShowSupportModal">
			<div class="flex-row flex-end">
				<img src="../assets/market/close_icon.png" class="close-icon" @click="closeSupportModal()" />
			</div>

			<div class="flex-row align-center">
				<img :src="info.detailsPic" class="good-img" />
				<div class="flex-column">
					<div class="good-name">{{info.name}}</div>
					<div class="modal-support-num">已有{{info.quantity-info.surplusQuantity}}人支持</div>
				</div>
			</div>

			<div class="buy-tishi-sec flex-column align-center">
				<img src="../assets/market/buy_tishi.png" class="buy-tishi" />
				<div class="buy-tishi-text">NFT买入后属个人数字财产，不支持退换。个人对其数字财产具有处置权，如出售。禁止未成年人买卖NFT。禁止炒作NFT。NFT的二次交易，由<span
						space="nbsp" class="blue-text"> 万物数组C2C市场 </span>提供服务。</div>
			</div>
			<div class="fengexian"></div>
			<div class="suppor-num-title">支持金额</div>
			<div class="suppor-num-data">￥{{info.price}}</div>
			<div class="modal-suppor-btn" @click.stop="choosePay()">立即支持</div>
			<div class="flex-row align-center justify-center agree-sec" @click.stop="changeArg()">
				<div :class="arg ?'agree-btn':'agree-btn-false'"></div>
				<div class="agree-text">我已阅读并同意<span class="agree-file">《用户协议》</span></div>
			</div>
		</div>
		<div class="pay-wrap" v-if="payModel">
			<div class="pay-type-wrap flex-row align-center space-around">
				<div class="pay-type flex-column align-center" @click="choosePayType(1)">
					<img src="../assets/ye.png" class="pay-img" />
					<div class="pay-text">余额</div>
					<div class="pay-radio" :class="payType == 1?'pay-radio-sel':''"></div>
				</div>
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(2)">
					<img src="../assets/wx.png" class="pay-img" />
					<div class="pay-text">微信</div>
					<div class="pay-radio" :class="payType == 2?'pay-radio-sel':''"></div>
				</div> -->
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(3)">
					<img src="../assets/zfb.png" class="pay-img" />
					<div class="pay-text">支付宝</div>
					<div class="pay-radio" :class="payType == 3?'pay-radio-sel':''"></div>
				</div> -->
			</div>
			<div class="btn-pay" @click.stop="confirmPay()">支付</div>
		</div>
		<div class="model" v-if="payModel" @click.stop="closePay">
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import LoginWrap from '@/components/Login.vue';
	export default {
		data() {
			return {
				buyModal: false,
				id: '',
				info: {},
				isShowSupportModal: false, //是否弹出立即支持弹窗
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				showLogin: false,
				type: 1,
				payModel: false,
				payType: 1,
				isBuy: false,
				arg: false
			}
		},
		components: {
			LoginWrap
		},
		methods: {
			changeArg() {
				this.arg = !this.arg
				console.log(this.arg)
			},
			checkBuyInfo(num) {
				const that = this;
				if (!that.$store.state.user.token) {
					that.login();
					return;
				}
				let params = new FormData()
				params.append('blindBoxId', that.id)
				params.append('buyType', 1)
				that.axios.post(config.requestUrl + '/front/blindBox/checkBuyInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.price = that.info.price * num;
						that.num = num;
						that.showSupportModal();
					} else if (response.data.code == -1) {
						that.msg = "请关注公众号接收发售提醒"
						console.log('已结束')
						Toast.fail(response.data.msg);
						// that.showSupportModal();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login();
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			choosePay() {
				if (!this.arg) {
					Toast('请阅读并同意用户协议');
					return;
				}
				this.type = 1;
				this.payModel = true;
				this.closeSupportModal()
			},
			confirmPay() {
				console.log('confirmPay' + this.type)
				if (this.payType == 1) {
					this.yePay();
					// this.getWalletByCurrencyId();
				} else if (this.payType == 2) {
					this.wxPay();
					console.log("微信支付")
				} else if (this.payType == 3) {
					this.zfbPay();
				}

			},

			yePay(verify) {
				const that = this
				if (that.isBuy) return;
				that.isBuy = true;
				Toast.loading({
					message: '正在抢单中。。。',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('blindBoxId', that.id)
				params.append('buyType', that.num == 1 ? 1 : 2)
				params.append('payType', 1)
				if (verify) {
					params.append('codeValue', that.verifyCode)
					params.append('key', that.verifyKey)

				}
				let query = {
					blindBoxId: that.id,
					buyType: that.num
				}
				that.axios.post(config.requestUrl + '/front/order/createOrderByBlindBox', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.orderInfo = response.data.obj;
						that.payModel = false;
						Toast.success('支付成功')
						setTimeout(() => {
							that.$router.replace({
								path: "/support",
							})
						}, 1200)
					} else if (response.data.code == -7) {
						Toast.clear()
						that.payModel = false;
						that.getVerifyCode()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
					that.isBuy = false;
				}, response => {
					that.isBuy = false;
					Toast.fail('抢单失败，点支付继续抢单');
				})
			},
			getVerifyCode() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			login() {
				this.showLogin = true;
			},
			closeLogin() {
				this.showLogin = false;
			},
			showSupportModal() {
				if (!this.$store.state.user.token) {
					this.login();
				} else {
					this.isShowSupportModal = true
				}
			},
			closeSupportModal() {
				this.isShowSupportModal = false
			},
			getDetail() {
				const that = this;
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = new FormData()
				params.append('blindBoxId', that.id)
				that.axios.post(config.requestUrl + '/front/blindBox/getInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let info = response.data.obj;
						info.surplusQuantity = parseInt(info.surplusQuantity) < 0 ? 0 : info.surplusQuantity;
						info.targetAmount = util.accMul(info.quantity, info.price);
						info.surplusDay = util.surplusDay(info.startTime, info.endTime);
						let progress = 0;
						if (info.soldOutAmount > 0 && info.quantity > 0 && info.price >
							0) {
							progress = util.accDi(info.soldOutAmount, util.accMul(info.quantity, info.price)) * 100
						}

						progress = progress.toFixed(2);
						info.progress = progress;
						that.info = info;
						console.log(info)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			console.log(111)
		},
		activated() {
			console.log('activated')
			document.body.scrollIntoView()
			const id = this.$route.query.id;
			if (!id) {
				this.$router.push('/')
			}
			this.id = id;
			this.getDetail();
		},
	}

</script>

<style scoped="scoped">
	.container {
		width: 100%;
		/* height: 100%; */
		background: rgba(203, 203, 203, 0.1);
		min-height: 100vh;
		padding-bottom: 108px;
		padding-top: 0 !important;
	}

	.good-item {
		width: 750px;
		background: #FFFFFF;
	}

	.good-item-top {
		width: 750px;
		height: 400px;
		background: #F7F7F7;
	}

	.good-item-img {
		width: 648px;
		height: 365px;
		margin-left: 51px;
		margin-top: 17px;
	}

	.good-item-num {
		font-size: 26px;
		font-family: VonwaonBitmap;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 49px;
		width: 90px;
		height: 49px;
		background: linear-gradient(142deg, #7CC2FF 0%, #A144FF 100%);
		border-radius: 0 0 10px 0;
		text-align: center;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.food-item-finish-icon {
		font-size: 26px;
		font-family: VonwaonBitmap;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 49px;
		width: 90px;
		height: 49px;
		background: linear-gradient(180deg, #FF9347 0%, #FF6900 100%);
		border-radius: 0 0 10px 0;
		text-align: center;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.new-icon {
		width: 60px;
		height: 32px;
	}

	.good-item-bottom {
		margin-left: 30px;
		margin-top: 20px;
	}

	.good-item-name {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
		letter-spacing: 1px;
		margin-left: 10px;
	}

	.good-item-desc {
		width: 690px;
		font-size: 26px;
		font-family: PingFangSC-Regular, PingFang SC;
		color: rgba(0, 0, 0, 0.5);
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 19px;
	}

	.mt20 {
		margin-top: 20px;
	}

	.good-item-price {
		font-size: 32px;
		font-weight: bold;
		color: #FA0C0C;
		line-height: 32px;
		letter-spacing: 1px;
		margin-right: 2px;
	}

	.good-item-qi {
		font-size: 20px;
		color: #FA0C0C;
		line-height: 32px;
		letter-spacing: 1px;
	}

	/* 众筹进度部分开始 */
	.data-sec {
		width: 690px;
		height: 170px;
		background: rgba(0, 0, 0, 0.04);
		border-radius: 10px;
		margin-top: 20px;
		box-sizing: border-box;
		padding: 20px 20px 22px 20px;
	}

	.progress-bg {
		width: 580px;
		height: 20px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.progress {
		width: 398px;
		height: 20px;
		background: linear-gradient(270deg, #FF8D3D 0%, #FFC9AA 100%);
		border-radius: 10px;
	}

	.huomiao-icon {
		width: 13px;
		height: 16px;
		margin-left: 10px;
	}

	.progress-num {
		margin-left: 4px;
		font-size: 18px;
		color: #000000;
		line-height: 25px;
	}

	.num-list {
		margin-top: 20px;
	}

	.num {
		text-align: center;
		width: 120px;
		height: 48px;
		background: #FFFFFF;
		border-radius: 24px;
		font-size: 26px;
		font-weight: bold;
		color: #FA6400;
		line-height: 48px;
	}

	.num-name {
		font-size: 22px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		margin-top: 10px;
	}

	/* 众筹进度部分结束 */
	.sponsor-sec {
		width: 690px;
		height: 290px;
		background: rgba(0, 0, 0, 0.04);
		border-radius: 10px;
		margin-top: 10px;
		padding: 20px;
		box-sizing: border-box;
	}

	.sponsor-icon {
		width: 32px;
		height: 32px;
	}

	.sponsor-name {
		margin-left: 10px;
		font-size: 26px;
		font-weight: bold;
		color: #000000;
		line-height: 26px;
		letter-spacing: 1px;
	}

	.sponsor-con-sec {
		margin-top: 4px;
	}

	.sponsor-con-bg {
		width: 650px;
		height: 214px;
	}

	.sponsor-con {
		width: 606px;
		height: 160px;
		font-size: 22px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 40px;
		position: absolute;
		top: 34px;
		left: 22px;
	}

	.detail-sec {
		margin-top: 21px;
	}

	.detai-title_left {
		width: 139px;
		height: 9px;
		background: linear-gradient(270deg, #C3BEFF 0%, #FFFFFF 100%);
	}

	.detai-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
		letter-spacing: 1px;
		margin-left: 13px;
	}

	.detai-title_right {
		width: 139px;
		height: 9px;
		background: linear-gradient(270deg, #FFFFFF 0%, #C3BEFF 100%);
	}

	.detail-con {
		width: 690px;
		height: 124px;
		background: rgba(0, 0, 0, 0.04);
		border-radius: 10px;
		margin-top: 25px;
	}

	.detail-con>>>img {
		max-width: 100% !important;
		height: auto !important;
	}

	.bot-sec {
		width: 750px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px -2px 0px 0px #A3A3A3;
		border-radius: 10px 10px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
	}

	.report-btn {
		width: 160px;
		height: 54px;
		background: rgba(255, 198, 140, 0.6);
		border-radius: 27px;
		margin-left: 30px;
		margin-right: 30px;
	}

	.report-icon {
		width: 18px;
		height: 20px;
		margin-right: 10px;
	}

	.report-name {
		font-size: 20px;
		font-weight: bold;
		color: #FA6400;
		line-height: 28px;
	}

	.support-btn {
		width: 500px;
		height: 54px;
		background: #6236FF;
		border-radius: 27px;
	}

	.support-name {
		font-size: 26px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 37px;
		letter-spacing: 1px;
	}

	.support-num {
		font-size: 26px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 37px;
		letter-spacing: 1px;
		margin-left: 55px;
	}

	/* 立即支持弹窗开始 */
	.support-modal {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 750px;
		height: 1234px;
		background: #FFFFFF;
		border-radius: 30px 30px 0px 0px;
		padding: 30px 30px 0 30px;
		box-sizing: border-box;
		z-index: 1001;
	}

	.close-icon {
		width: 41px;
		height: 41px;
		justify-content: end;
	}

	.good-img {
		width: 150px;
		height: 150px;
		background: #F7F7F7;
		border-radius: 10px;
		margin-right: 20px;
	}

	.good-name {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
		letter-spacing: 1px;
		margin-bottom: 22px;
	}

	.modal-support-num {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 34px;
		letter-spacing: 1px;
	}

	.buy-tishi-sec {
		margin-top: 30px;
		width: 690px;
		height: 210px;
		background: rgba(224, 32, 32, 0.06);
		border-radius: 10px;
		padding: 15px 30px 33px 30px;
		box-sizing: border-box;
	}

	.buy-tishi {
		width: 197px;
		height: 40px;
		margin-bottom: 8px;
	}

	.buy-tishi-text {
		width: 630px;
		height: 114px;
		font-size: 22px;
		color: #B45050;
		line-height: 40px;
	}

	.fengexian {
		width: 690px;
		height: 1px;
		background: rgba(0, 0, 0, 0.1);
		margin-top: 33px;
	}

	.suppor-num-title {
		font-size: 26px;
		font-weight: bold;
		color: #000000;
		line-height: 37px;
		letter-spacing: 1px;
		margin-top: 42px;
	}

	.suppor-num-data {
		width: 220px;
		height: 60px;
		background: rgba(0, 0, 0, 0.04);
		border-radius: 10px;
		font-size: 28px;
		font-weight: bold;
		color: #FF000A;
		line-height: 60px;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 27px;
	}

	.modal-suppor-btn {
		margin-top: 394px;
		text-align: center;
		width: 690px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 32px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 80px;
		letter-spacing: 1px;
	}

	.agree-sec {
		margin-top: 30px;
	}

	.agree-btn {
		width: 15px;
		height: 15px;
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 50%;
		background: #6236FF;
	}

	.agree-btn-false {
		width: 15px;
		height: 15px;
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 50%;
	}

	.agree-text {
		font-size: 22px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		margin-left: 5px;
	}

	.blue-text {
		color: #0091FF;
		border-bottom: 1px solid #0091FF;
	}

	.agree-file {
		color: #6236FF;
	}

	/* 立即支持弹窗结束 */

	/*支付方式弹窗*/

	.pay-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 650px;
		height: 630px;
		margin: auto;
		background: #FFFFFF;
		border-radius: 22px;
		z-index: 1001;
		padding: 150px 30px 50px;
		box-sizing: border-box;
	}

	.pay-type-wrap {}

	.pay-type {}

	.pay-img {
		width: 140px;
		height: 140px;
	}

	.pay-text {
		margin-top: 20px;
		font-size: 28px;
		color: #000000;
	}

	.pay-radio {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		background: #DDDDDD;
		border-radius: 100%;
		border: 6px solid #DDDDDD;
	}

	.pay-radio-sel {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		background: #3FCF77;
		border-radius: 100%;
		border: 6px solid #DDDDDD;
	}

	.btn-pay {
		width: 590px;
		height: 100px;
		background: #6236FF;
		border-radius: 60px;
		line-height: 100px;
		font-size: 38px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		margin-top: 88px;
	}

	/*支付方式弹窗结束*/

</style>

